import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Suspense as _Suspense, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "min-h-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNav = _resolveComponent("MainNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createBlock(_Suspense, null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_ctx.$route.fullPath !== "/")
          ? (_openBlock(), _createBlock(_component_MainNav, { key: 0 }))
          : _createCommentVNode("", true),
        _createTextVNode(),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(), _createBlock(_component_router_view, {
            key: _ctx.$route.fullPath
          }))
        ]),
        _createTextVNode(),
        (_ctx.$route.fullPath !== "/")
          ? (_openBlock(), _createBlock(_component_Footer, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}