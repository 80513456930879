
import * as Vue from 'vue';
import { ChevronDoubleRightIcon } from '@heroicons/vue/24/solid';
import { ArrowRightIcon } from '@heroicons/vue/24/outline'

export default Vue.defineComponent({
  components: {
    ChevronDoubleRightIcon,
    ArrowRightIcon
  }
});
