
import * as Vue from "vue";
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue';
import { ArrowRightIcon } from '@heroicons/vue/24/outline'
import SearchForm from './SearchForm.vue';
import Footer from '@/layouts/Footer.vue';

export default Vue.defineComponent({
  props: {
    showPadding: {
      type: Boolean,
      default: true,
    },
    navBgColor: {
      type: String,
    },
    isFixed: {
      type: Boolean,
      default: true,
    }
  },
  components: {
    ArrowRightIcon,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    SearchForm,
    Footer,
  },
  setup(props: any) {
    const popoverHover = Vue.ref(false)
    const popoverTimeout = Vue.ref()

    return {popoverHover,
      popoverTimeout,
      manuallyClosed: {
        datanet: false,
        mainchain: false,
        argon: false,
      },
      searchIsActive: Vue.ref(false),
      isDark: props.navBgColor ? true : false,
      backgroundColor: props.navBgColor ? props.navBgColor : '#FAFBFB',
    }
  },
  methods: {
    toggleSearch() {
      this.searchIsActive = !this.searchIsActive;
    },

    isActiveNav(name: string): boolean {
      if (name === 'datanet' && this.$route.path === '/') return true;
      return this.$route.path.startsWith(`/${name}`);
    },

    clickPopoverButton($event: any, name: string, close: any, isOpen: boolean) {
      if (isOpen) {
        close();
        (this.manuallyClosed as any)[name] = true;
        this.$router.push(`/${name}`);
      } else {
        $event.currentTarget.click()
      }
      $event.stopPropagation();
    },

    hoverPopover($event: any, name: string, isOpen: boolean): void {
      if ((this.manuallyClosed as any)[name]) return;
      this.popoverHover = true
      if (!isOpen) {
        $event.currentTarget.click()
      }
    },

    closePopover($event: any, close: any) {
      (this.manuallyClosed as any)[name] = false;
      this.popoverHover = false;
      close()
    },

    leavePopover(name: string, close: any): void {
      (this.manuallyClosed as any)[name] = false;
      this.popoverHover = false;
      if (this.popoverTimeout) clearTimeout(this.popoverTimeout)
      this.popoverTimeout = setTimeout(() => {
        if (!this.popoverHover) {
          close()
        }
      }, 100)
    }
  }
});
