
import * as Vue from "vue";
import { useRouter } from 'vue-router';
import MainNav from '@/layouts/MainNav.vue';
import Footer from '@/layouts/Footer.vue';
import DatanetIndex from '@/pages/datanet/Index.vue';

let lastScroll = 0;
function setScrollVar() {
  const documentElement = document.documentElement
  const navObserverElement = document.querySelector('#NAV-OBSERVER') as HTMLElement;
  const navRect = navObserverElement.getBoundingClientRect();
  const navTopDistance = navRect.top + documentElement.scrollTop;
  const percentOfScreenHeightScrolled = documentElement.scrollTop / navTopDistance;
  const scroll = Math.min(percentOfScreenHeightScrolled * 100, 100);
  documentElement.style.setProperty('--scroll', scroll.toString());

  const currentPos = documentElement.scrollTop;
  if (currentPos !== lastScroll && currentPos !== 0 && currentPos !== navTopDistance) {
    if (documentElement.scrollTop > lastScroll) navScroll('down', navTopDistance);
    else navScroll('up', 0);
    lastScroll = documentElement.scrollTop;
  }

  if (scroll > 25) {
    document.querySelector('.NAV')?.classList.add('After25');
  } else {
    document.querySelector('.NAV')?.classList.remove('After25');
  }
}

let isScrolling = false;
function navScroll(direction: 'up' | 'down', toPos: number) {
  if (isScrolling) return;
  isScrolling = true;
  // window.scrollTo(0, toPos);
  isScrolling = false;

  // setTimeout(() => {
  //   navScroll(direction);
  // }, 16);
}

export default Vue.defineComponent({
  components: {
    DatanetIndex,
    MainNav,
    Footer,
  },
  setup() {
    const $router = useRouter();
    const showNav = Vue.ref(false);
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const klass = entry.target.id.replace('-OBSERVER', '');
        if (entry.isIntersecting) {
          document.querySelector(`.${klass}`)?.classList.remove('activated');
          showNav.value = false;
        } else {
          // $router.push(`/datanet`);
          document.querySelector(`.${klass}`)?.classList.add('activated');
          showNav.value = true;
        }
      });
    }, {
      rootMargin: '0px',
      threshold: 0,
    });
    return {
      observer,
      showNav,
    }
  },

  mounted() {
    // setTimeout(() => window.scrollTo(0, 0), 1);
    window.addEventListener('scroll', setScrollVar)
    window.addEventListener('resize', setScrollVar)

    this.observer.observe(document.querySelector('#NAV-OBSERVER') as HTMLElement);

    setScrollVar();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', setScrollVar);
    window.removeEventListener('resize', setScrollVar);

  },
  methods: {
    scrollToNav($event: any, count = 0) {
      if (count > 1) return;
      const documentElement = document.documentElement
      const navObserverElement = document.querySelector('#DatanetIndex') as HTMLElement;
      const navRect = navObserverElement.getBoundingClientRect();
      const navTopDistance = navRect.top + documentElement.scrollTop;
      window.scrollTo(0, navTopDistance - 53);
      setTimeout(() => {
        this.scrollToNav($event, count + 1);
      }, 1);
    },
  },
});
