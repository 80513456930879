import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNav = _resolveComponent("MainNav")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.isFixed ? 'pt-[3.25rem]' : '', _ctx.isDark ? 'isDark' : ''])
  }, [
    _createVNode(_component_MainNav),
    _createTextVNode(),
    _createElementVNode("div", {
      class: _normalizeClass([[_ctx.showPadding ? 'p-24' : ''], "min-h-screen"])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    _createTextVNode(),
    _createVNode(_component_Footer)
  ], 2))
}