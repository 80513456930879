
import * as Vue from "vue";
import { PopoverPanel } from '@headlessui/vue'

export default Vue.defineComponent({
  props: {
    subPath: {
      default: '',
    }
  },
  components: {
    PopoverPanel,
  }
});
