import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: `/featured/${_ctx.logo.name}`,
    class: _normalizeClass(["LegoBlock Component", { purple: _ctx.purple, alignRight: _ctx.alignRight }])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_inline_svg, {
        src: _ctx.logo.src
      }, null, 8, ["src"])
    ]),
    _: 1
  }, 8, ["to", "class"]))
}