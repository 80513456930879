
import * as Vue from "vue";
import getLogo from '@/lib/logosForHomepage';
import { Popover, PopoverButton } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import ActiveToolsOverlay from "@/components/ActiveToolsOverlay.vue";
import LegoBlock from '@/components/LegoBlock.vue';

const rows = [
  5,
  4,
  4,
  4,
  3,
  4,
  3,
  4,
  3,
  4,
  4,
  5,
  5,
];

export default Vue.defineComponent({
  components: {
    Popover,
    PopoverButton,
    ChevronDownIcon,
    ActiveToolsOverlay,
    LegoBlock,
  },
  setup() {
    return {
      rows,
    }
  },
  methods: {
    getLogo,
  },
});
