
import * as Vue from "vue";
import SearchForm from './SearchForm.vue';
import Footer from '@/layouts/Footer.vue';

export default Vue.defineComponent({
  props: {
    showPadding: {
      type: Boolean,
      default: true,
    },
    navBgColor: {
      type: String,
    },
    isFixed: {
      type: Boolean,
      default: true,
    }
  },
  components: {
    SearchForm,
    Footer,
  },
  setup(props: any) {
    return {
      isDark: props.navBgColor ? true : false,
      backgroundColor: props.navBgColor ? props.navBgColor : '#FAFBFB',
    }
  },
  methods: {}
});
