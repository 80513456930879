
import * as Vue from "vue";

export default Vue.defineComponent({
  props: {
    logo: Object,
    purple: Boolean,
    alignRight: Boolean,
  },
});
